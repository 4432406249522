import React from 'react';

// import logo from './logo.png';
import Dashboard from "./Dashboard";

class AppHome extends React.Component {
    render() {
        return <Dashboard/>;
    }
}

export default AppHome;