import React, {useEffect, useState} from "react"
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import CardContent from "@mui/material/CardContent";
import {Bar} from "react-chartjs-2";
import {
    Checkbox,
    Container,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import * as utils from "./utils";
import * as _ from "lodash";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {DataGrid} from "@mui/x-data-grid";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const dataFields = {
    // "Students": (item) => item.hasOwnProperty("total") ? item["total"] : 0,
    // "Teachers": "total",
    // "Classrooms": "total",
    "Boys": (item) => item['gender'] === "M" ? item["total"] : 0,
    "Girls": (item) => item['gender'] === "F" ? item["total"] : 0,
    // "Repeating": (item) => item['repeating'] > 0 ? item["total"] : 0,
    "Repeating Boys": (item) => item['repeating'] > 0 && item['gender'] === "M" ? item["total"] : 0,
    "Repeating Girls": (item) => item['repeating'] > 0 && item['gender'] === "F" ? item["total"] : 0,
    // "Drop Off": (item) => item['dropOff'] === true ? item["total"] : 0,
    "Drop Off Boys": (item) => item['dropOff'] === true && item['gender'] === "F" ? item["total"] : 0,
    "Dro Off Girls": (item) => item['dropOff'] === true && item['gender'] === "M" ? item["total"] : 0,
}
const labels = Object.keys(dataFields);
const indicators = Object.values(dataFields)
const dashboardItems = [
    {
        label: "School Year",
        field: "schoolYear",
        active: true
    },
    {
        label: "Region",
        field: "region",
        active: true
    },
    {
        label: "Academy",
        field: "academy",
        active: false
    },
    {
        label: "Cycle",
        field: "cycle",
        active: false
    },
    {
        label: "Level",
        field: "level",
        active: false
    },
    // {
    //     label: "Gender",
    //     field: "gender",
    //     active: false
    // },
    // {
    //     label: "Educational Activity Center",
    //     field: "educationalActivityCenter",
    //     active: false
    // },
];
const parameters = ["schoolYear", "region", "academy", "educationalActivityCenter", "cycle", "level", "gender", "dropOff", "repeating"];
const select = [...parameters, "total"];
const columns = [{field: 'id', headerName: 'ID', width: 90}, ...select.map(item => {
    return {
        field: item,
        headerName: _.startCase(item),
        editable: false,
        minWidth: 250,
        // resizable: true,
    }
})];

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        interaction: {
            mode: 'index',
            axis: 'y'
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    let sum = _.reduce(context.dataset.data, (cumul, curr) => cumul + curr, 0);
                    return (context.dataset.data[context.dataIndex] * 100 / sum).toFixed(2) + "%";
                }
            }
        },
    },
    scales: {
        x: {
            stacked: false,
        },
        y: {
            stacked: false,
        },
    },
};
const dynamicColors = () => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
};
const toDataset = (item, labelField = 'label') => {
    return {
        label: item[labelField],
        data: labels.map(label => item[dataFields[label]]),
        backgroundColor: dynamicColors()
    }
}

const currentYear = new Date(_.now()).getFullYear()

function DashboardItem(props) {
    const [active, setActive] = useState(props.item?.active === true);
    const elements = {
        labels,
        datasets: props.data.map(item => toDataset(item))
    }
    return <Card>
        <CardHeader title={props.label} subheader={props.schoolYear}
                    action={<Checkbox checked={active} onChange={e => setActive(!active)}/>}/>
        {active && <CardContent>
            <Bar options={options} data={elements}/>
        </CardContent>}
    </Card>
}

export default function Dashboard(props) {
    const [elements, setElements] = useState({});
    const [data, setData] = useState([]);
    const [schoolYear, setSchoolYear] = useState([currentYear]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            let path = `/api/student-stats/list?page=-1&select=${select.join(",")}`;
            let years = schoolYear.map(y => `schoolYear=${y}`).join("&")
            if (Boolean(years)) {
                path = `${path}&${years}`
            }
            console.log("path", path);
            utils.get(path)
                .then(response => {
                    console.log("response", response);
                    let elements = parameters.reduce((cumul, param) => {
                        let element = _.groupBy(response.data, param);
                        return {
                            ...cumul, [param]: _.keys(element).map(item => {
                                return {
                                    label: item,
                                    ..._.reduce(element[item], (cumul, curr) => {
                                        _.forEach(indicators, indicator => {
                                            /**
                                             * {
                                             *             if (typeof indicator === "function") {
                                             *                 return indicator(curr)
                                             *             }
                                             *             return curr[indicator]
                                             *         }
                                             */

                                            cumul[indicator] = cumul[indicator] + (typeof indicator === "function" ? indicator(curr) : curr[indicator]);
                                        });
                                        return cumul
                                    }, _.reduce(indicators, (cumul, curr) => {
                                        return {...cumul, [curr]: 0}
                                    }, {}))
                                };
                            })
                        };
                    }, {});
                    setData(response.data.map((item, index) => {
                        if (!item.hasOwnProperty("id")) {
                            item["id"] = index + 1;
                        }
                        return item;
                    }));
                    setElements(elements);
                    setLoaded(true);
                    console.log("all", elements);
                })
                .catch(error => {
                    console.log("response", error.response);
                });
        }
    }, [loaded, schoolYear]);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2} margin={2}>
                <Grid item>
                    <FormControl variant="filled" sx={{ m: 1, minWidth: 220 }}>
                        <InputLabel id="demo-simple-select-standard-label">School Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={schoolYear}
                            renderValue={(selected) => selected.join(', ')}
                            input={<OutlinedInput label="School Year" />}
                            multiple
                            onChange={e => {
                                setLoaded(false);
                                setSchoolYear(e.target.value);
                            }}
                            fullWidth
                            label="School Year"
                        >
                            {_.range(6).map(it => <MenuItem key={it} value={currentYear - it}>
                                <Checkbox checked={schoolYear.indexOf(currentYear - it) > -1} />
                                <ListItemText primary={currentYear - it} />
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Box sx={{height: 400, width: '100%'}}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 50, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Grid>
                {
                    dashboardItems
                        // .filter(item => item.active !== false)
                        .map(item => <Grid key={item.field} item lg={6} xs={12}>
                            {
                                loaded ?
                                    <DashboardItem schoolYear={schoolYear.join(', ')} item={item} data={elements[item.field]}
                                                   label={item.label}/> :
                                    <Box sx={{display: 'flex'}}>
                                        <CircularProgress/>
                                    </Box>
                            }
                        </Grid>)
                }
            </Grid>
        </Container>
    );
}