import React from "react";
import * as utils from "./utils";

class AppLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            organization: "",
            remember: false,
            error: false,
            organizations: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (name === "remember") {
            value = event.target.checked;
        }
        this.setState({
            [name]: value,
            error: false,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let state = {
            username: this.state.username,
            password: this.state.password,
        };
        utils
            .post("/api/auth", state, {})
            .then((res) => {
                utils.storeToken(res.headers);
                let {data: result} = res;
                console.log("result", result);
                if (typeof this.props.onLogin === "function") {
                    if (result.code === 400) {
                        this.props.onLogin({result});
                        this.setState({
                            error: true,
                        });
                    } else {
                        this.props.onLogin({result});
                        this.setState({
                            error: false,
                        });
                    }
                }
            })
            .catch((res) => {
                let {data: error} = res.response;
                console.log("error", error);
                if (typeof this.props.onLogin === "function") {
                    this.props.onLogin(error);
                }
            });
    }

    render() {
        return (
            <section className="container g-pt-100 g-pb-20">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-lg-5 flex-md-unordered g-mb-80">
                        <div className="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-30 g-py-50 mb-4">
                            <header className="text-center mb-4">
                                <h1 className="h3 g-color-black g-font-weight-300 text-capitalize">
                                    Login to your account
                                </h1>
                            </header>

                            <form
                                className="g-py-15"
                                onSubmit={this.handleSubmit}
                            >
                                {/*<div className="mb-4">*/}
                                {/*    <div className="input-group g-brd-primary--focus">*/}
                                {/*        <select*/}
                                {/*            className={*/}
                                {/*                "form-control g-color-black g-brd-gray-light-v3 g-py-15 g-px-15" +*/}
                                {/*                (this.state.error*/}
                                {/*                    ? " error"*/}
                                {/*                    : "")*/}
                                {/*            }*/}
                                {/*            type="text"*/}
                                {/*            name="organization"*/}
                                {/*            value={*/}
                                {/*                this.state.organization*/}
                                {/*            }*/}
                                {/*            onChange={this.handleChange}*/}
                                {/*        >*/}
                                {/*            <option value="">*/}
                                {/*                Organization*/}
                                {/*            </option>*/}
                                {/*            {this.state.organizations.map(*/}
                                {/*                (org) => {*/}
                                {/*                    return (*/}
                                {/*                        <option*/}
                                {/*                            key={org.value}*/}
                                {/*                            value={org.value}*/}
                                {/*                        >*/}
                                {/*                            {org.label}*/}
                                {/*                        </option>*/}
                                {/*                    );*/}
                                {/*                }*/}
                                {/*            )}*/}
                                {/*        </select>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="mb-4">
                                    <div className="input-group g-brd-primary--focus">
                                        <input
                                            className={
                                                "form-control g-color-black g-brd-gray-light-v3 g-py-15 g-px-15" +
                                                (this.state.error
                                                    ? " error"
                                                    : "")
                                            }
                                            type="text"
                                            name="username"
                                            placeholder="Username"
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="input-group g-brd-primary--focus mb-4">
                                        <input
                                            className={
                                                "form-control g-color-black g-brd-gray-light-v3 g-py-15 g-px-15" +
                                                (this.state.error
                                                    ? " error"
                                                    : "")
                                            }
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row justify-content-between mb-4">
                                    <div className="col align-self-center">
                                        <label
                                            className="form-check-inline u-check g-color-gray-dark-v5 g-font-size-13 g-pl-25 mb-0">
                                            <input
                                                className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                type="checkbox"
                                                name="remember"
                                                checked={this.state.remember}
                                                onChange={this.handleChange}
                                            />
                                            <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                                                <i
                                                    className="fa"
                                                    data-check-icon=""
                                                />
                                            </div>
                                            Keep signed in
                                        </label>
                                    </div>
                                    <div className="col align-self-center text-right">
                                        <a className="g-font-size-13" href="/">
                                            Forgot password?
                                        </a>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <button
                                        className="btn btn-block u-btn-primary g-py-13"
                                        type="submit"
                                    >
                                        Login
                                    </button>
                                </div>

                                <div className="d-flex justify-content-center text-center g-mb-30">
                                    <div
                                        className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                                    <span className="align-self-center g-color-gray-dark-v5 mx-4">
                                        OR
                                    </span>
                                    <div
                                        className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                                </div>

                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <button
                                            className="btn btn-block u-btn-facebook g-py-13 mr-2"
                                            type="button"
                                        >
                                            <i className="mr-1 fa fa-facebook"/>
                                            Facebook
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className="btn btn-block u-btn-twitter g-py-13 ml-2"
                                            type="button"
                                        >
                                            <i className="mr-1 fa fa-twitter"/>
                                            Twitter
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="text-center">
                            <p className="g-color-gray-dark-v5 mb-0">
                                Don't have an account?{" "}
                                <a
                                    className="g-font-weight-600"
                                    href="page-signup-12.html"
                                >
                                    signup
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="col-md-6 flex-md-first g-mb-80">
                        <div className="mb-5">
                            <h2 className="h1 g-font-weight-300 mb-3">
                                Welcome in Djamma Dev
                            </h2>
                            <p className="g-color-gray-dark-v5">
                                Manage your data with our Admin application.
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-lg-9">
                                <div className="media mb-4">
                                    <div className="d-flex mr-3">
                                        <span className="align-self-center u-icon-v1 u-icon-size--lg g-color-primary">
                                            <i className="icon-finance-168 u-line-icon-pro"/>
                                        </span>
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h3 className="h5">
                                            Reliable contracts
                                        </h3>
                                        <p className="g-color-gray-dark-v5 mb-0">
                                            Reliable contracts,
                                            multifanctionality &amp; best usage
                                            of Unify template
                                        </p>
                                    </div>
                                </div>
                                <div className="media mb-4">
                                    <div className="d-flex mr-3">
                                        <span className="align-self-center u-icon-v1 u-icon-size--lg g-color-primary">
                                            <i className="icon-finance-193 u-line-icon-pro"/>
                                        </span>
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h3 className="h5">Security</h3>
                                        <p className="g-color-gray-dark-v5 mb-0">
                                            Secure &amp; integrated options to
                                            create individual &amp; business
                                            websites
                                        </p>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="d-flex mr-3">
                                        <span className="align-self-center u-icon-v1 u-icon-size--lg g-color-primary">
                                            <i className="icon-finance-122 u-line-icon-pro"/>
                                        </span>
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h3 className="h5">Maintain</h3>
                                        <p className="g-color-gray-dark-v5 mb-0">
                                            We get it, you're busy and it's
                                            important that someone keeps up with
                                            marketing
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AppLogin;
